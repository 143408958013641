import React, { useState } from 'react'
import {
    Button, Icon, Text, LegacyCard, LegacyStack, Box,
} from "@shopify/polaris"
import { ChevronUpIcon } from "@shopify/polaris-icons"
import update from 'immutability-helper'
import CardDivider from '../../../../components/cardDivider'
import ProductOffer from "./productOffer"
function OfferProductsSetting(props) {
  const {
    host,
    shop,
    state,
    setState,
    productPickerShow,
    error,
    productEdit,
    setProductEdit,
    currency,
    currencySymbol = "$",
    token,
    checkoutOfferType,
  } = props
  const [downsellEdit, setDownsellEdit] = useState(state.product.length > 1 && state.product[1].shownWhenFirst ? state.product[1].shownWhenFirst : null)
  const addDownsellOffer = () => {
    setDownsellEdit("denied")
    setState(
      update(state, {
        product: {
          $push: [
            {
              shownWhenFirst: "denied",
              expiration: 15,
              products: [
                {
                  discount: { sym: currencySymbol, value: 0 },
                },
              ],
              productsPerRow: 1,
              productNumber: 1,
            },
          ],
        },
      })
    )
  }
  return (
    <div style={{ marginTop: "1.6rem", marginBottom: "1.6rem" }}>
      <div>
        {state.product.map((offer, index) => {
          return (
            <ProductOffer
              key={index}
              offer={offer}
              index={index}
              host={host}
              shop={shop}
              token={token}
              state={state}
              setState={setState}
              error={error}
              productEdit={productEdit}
              setProductEdit={setProductEdit}
              productPickerShow={productPickerShow}
              currency={currency}
              currencySymbol={currencySymbol}
              checkoutOfferType={checkoutOfferType}
              downsellEdit={downsellEdit}
              setDownsellEdit={setDownsellEdit}
            />
          )
        })}
        {state.product.length < 2 ? (
          // we'll show the button when the downsell product is deleted
          <Box paddingBlockStart="400">
            <LegacyCard sectioned>
              <div>
              <LegacyStack alignment="center">
                  <LegacyStack.Item fill>
                  <Text as="h2" variant="headingMd">Add a downsell offer</Text>
                  <Text tone="subdued">
                    Condition for offer on first page 
                  </Text>
                  </LegacyStack.Item>
              </LegacyStack>
              </div>
              <CardDivider/>
              <Box paddingBlockStart="400">
                <Button onClick={addDownsellOffer}>
                    + {state.product.length > 0 ? "Add a downsell offer" : false}
                </Button>
              </Box>
            </LegacyCard>
          </Box>
        ) : (
          false
        )}
      </div>
    </div>
  )
}

export default OfferProductsSetting;